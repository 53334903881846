<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../../assets/img/index_a.png" alt="" width="28">
        <h2>{{ state == 1 ? '编辑' : '新增' }}实验</h2>
      </div>
      <el-button size="small" @click="$router.go(-1)">返回上一页</el-button>
    </div>
    <div class="contentBody">
      <el-form ref="addExper" size="small" :rules="rules" :model="addExper" class="addForm" label-suffix=":"
               label-width="120px">
        <h3 class="tit">基本信息</h3>
        <el-form-item label="实验编号" prop="number">
          <el-input v-model="addExper.number" clearable placeholder="请输入实验编号" class="smallWidth"></el-input>
        </el-form-item>
        <el-form-item label="实验名称" prop="name">
          <el-input v-model="addExper.name" clearable placeholder="请输入实验名称"></el-input>
        </el-form-item>
        <el-form-item label="实验概要" prop="summary">
          <el-input v-model="addExper.detail.summary" placeholder="请输入实验概要" type="textarea" rows="3"></el-input>
        </el-form-item>
        <el-form-item label="实验目的" prop="aim">
          <el-input v-model="addExper.detail.aim" placeholder="请输入实验目的" type="textarea" rows="3"></el-input>
        </el-form-item>
        <h3 class="tit">项目成员</h3>
        <div class="flex head">
          <el-form-item label="负责人" prop="principalName">
            <el-select
                class="smallWidth" value-key="userId"
                v-model="addExper.principalName"
                filterable clearable
                remote
                placeholder="请输入负责人姓名"
                :remote-method="remoteMethod"
                :loading="loading"
                @change="((val)=>{handleUser(val, 0)})"
            >
              <el-option
                  v-for="item in userSelect"
                  :key="item.userId"
                  :label="item.nickname"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="负责人电话" prop="principalPhone">
            <el-input v-model="addExper.principalPhone" clearable placeholder="请输入负责人电话" class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="负责人邮箱" prop="principalEmail">
            <el-input v-model="addExper.principalEmail" clearable placeholder="请输入负责人邮箱" class="smallWidth"></el-input>
          </el-form-item>
        </div>
        <!-- 项目成员-->
        <el-table
            :data="addExper.memberList" border
            :row-class-name="tableRowClassName"
            class="tableMember"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}">
          <el-table-column label="项目组成员姓名" width="180">
            <template slot-scope="scope">
              <el-select
                  value-key="userId"
                  v-if="scope.$index==0" size="small"
                  v-model="projectTeam.name"
                  filterable clearable
                  remote
                  placeholder="请输入项目组成员姓名"
                  :remote-method="remoteMethod"
                  :loading="loading" @change="((val)=>{handleUser(val, 1)})">
                <el-option
                    v-for="item in userSelect"
                    :key="item.userId"
                    :label="item.nickname"
                    :value="item">
                </el-option>
              </el-select>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-select
                      size="small"
                      value-key="userId"
                      v-model="scope.row.name"
                      filterable clearable
                      remote
                      placeholder="请输入项目组成员姓名"
                      :remote-method="remoteMethod"
                      :loading="loading" @change="((val)=>{handleUserTab(scope.row,val)})"
                  >
                    <el-option
                        v-for="item in userSelect"
                        :key="item.userId"
                        :label="item.nickname"
                        :value="item">
                    </el-option>
                  </el-select>
                </template>
                <span v-else>{{ scope.row.name }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="相关专业证书编号" min-width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input v-model="projectTeam.certNum" size="small" v-if="scope.$index==0"
                        placeholder="请输入成员专业证书编号"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入成员专业证书编号" v-model="scope.row.certNum"></el-input>
                </template>
                <span v-else>{{ scope.row.certNum }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="工作内容" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input v-model="projectTeam.jobContent" size="small" v-if="scope.$index==0"
                        placeholder="请输入工作内容"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入工作内容" v-model="scope.row.jobContent"></el-input>
                </template>
                <span v-else>{{ scope.row.jobContent }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="电话">
            <template slot-scope="scope">
              <el-input v-model="projectTeam.phone" size="small" v-if="scope.$index==0"
                        placeholder="请输入成员电话"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入成员电话" v-model="scope.row.phone"></el-input>
                </template>
                <span v-else>{{ scope.row.phone }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="Email" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input v-model="projectTeam.email" size="small" v-if="scope.$index==0"
                        placeholder="请输入成员Email"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入成员Email" v-model="scope.row.email"></el-input>
                </template>
                <span v-else>{{ scope.row.email }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div v-if="scope.$index==0" class="addColor">
                <el-button type="text" @click="addTeam()">添加</el-button>
                <el-button type="text" @click="reset">重置</el-button>
              </div>
              <div v-else class="editColor">
                <el-button type="text" @click="edit(scope.row,scope.$index)">{{ scope.row.show ? '保存' : "修改" }}
                </el-button>
                <el-button type="text" @click="copy(scope.row,scope.$index)">复制</el-button>
                <el-button type="text" @click="deleteA(scope.$index)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="btn">
          <el-button size="small" type="primary" @click="editAll">批量编辑</el-button>
          <el-button size="small" type="primary" @click="submit">批量保存</el-button>
        </div>
        <h3 class="tit">实验动物</h3>
        <el-form-item label="来源于实验室">
          <el-radio-group v-model="addExper.isLabAni" class="radioGroup smallWidth" @change="changeVal">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="flex head">
          <el-form-item label="来源">
            <template v-if="addExper.isLabAni==0">
              <el-select
                  allow-create
                  class="smallWidth"
                  v-model="addExper.aniOrder.aniSupply.supplier"
                  filterable
                  default-first-option
                  v-el-select-loadmore="loadAll"
                  placeholder="请选择来源">
                <el-option
                    v-for="item in restaurants"
                    :label="item.supplier"
                    :value="item.supplier"
                    :key="item.id"></el-option>
              </el-select>
            </template>
            <el-input v-else v-model="addExper.aniOrder.aniSupply.supplier" clearable placeholder="请输入来源" readonly
                      class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="动物品系" prop="strain">
            <el-input v-model="addExper.strain" clearable placeholder="请输入动物品系" class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="许可证" prop="prodCert">
            <el-input v-model="addExper.aniOrder.aniSupply.prodCert" clearable placeholder="请输入许可证"
                      class="smallWidth"></el-input>
          </el-form-item>
        </div>
        <div class="flex head">
          <el-form-item label="需要笼舍数量" prop="cageNum">
            <el-input v-model="addExper.cageNum" type="number" :min="0" placeholder="请输入需要笼舍数量"
                      class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="申请笼舍数量" prop="aniOrder.applyCageNum">
            <el-input v-model="addExper.aniOrder.applyCageNum" type="number" :min="0" placeholder="请输入申请笼舍数量"
                      class="smallWidth"></el-input>
          </el-form-item>
        </div>
        <div class="flex head">
          <el-form-item label="雌性入组数量">
            <el-input v-model="addExper.femaleNum" type="number" :min="0" placeholder="请输入雌性入组数量"
                      class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="雌性实验数量">
            <el-input v-model="addExper.female.count" type="number" :min="0" placeholder="请输入雌性实验数量"
                      class="smallWidth"></el-input>
          </el-form-item>
        </div>
        <div class="flex head">
          <el-form-item label="雌性周龄">
            <el-input v-model="addExper.female.weekDays" clearable placeholder="请输入雌性周龄" class="smallWidth">
              <span slot="suffix">周</span>
            </el-input>
          </el-form-item>
          <el-form-item label="雌性体重">
            <el-input v-model="addExper.female.weight" clearable placeholder="请输入雌性体重" class="smallWidth">
              <span slot="suffix">g</span>
            </el-input>
          </el-form-item>
        </div>
        <div class="flex head">
          <el-form-item label="雄性入组数量">
            <el-input v-model="addExper.maleNum" type="number" :min="0" placeholder="请输入雄性入组数量"
                      class="smallWidth"></el-input>
          </el-form-item>
          <el-form-item label="雄性实验数量">
            <el-input v-model="addExper.male.count" type="number" :min="0" placeholder="请输入雄性实验数量"
                      class="smallWidth"></el-input>
          </el-form-item>
        </div>
        <div class="flex head">
          <el-form-item label="雄性周龄">
            <el-input v-model="addExper.male.weekDays" clearable placeholder="请输入雌性周龄" class="smallWidth">
              <span slot="suffix">周</span>
            </el-input>
          </el-form-item>
          <el-form-item label="雄性体重">
            <el-input v-model="addExper.male.weight" clearable placeholder="请输入雌性体重" class="smallWidth">
              <span slot="suffix">g</span>
            </el-input>
          </el-form-item>
        </div>
        <el-form-item label="等级">
          <el-radio-group v-model="addExper.aniOrder.grade" class="radioGroup">
            <el-radio :label="0">普通级</el-radio>
            <el-radio :label="1">SPF</el-radio>
            <el-radio :label="2">清洁级</el-radio>
          </el-radio-group>
        </el-form-item>
        <h3 class="tit">实验时间</h3>
        <div class="flex head">
          <el-form-item label="计划开始时间">
            <el-date-picker
                size="small"
                v-model="addExper.startTime"
                type="date" @change="cleareEndData"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="计划结束时间">
            <el-date-picker
                size="small"
                v-model="addExper.period"
                type="date" :picker-options="pickerOptions"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <!--          <el-form-item label="计划实验周期" prop="period">
                      <el-input v-model="addExper.period" type="number" min="0" placeholder="请输入计划实验周期" class="smallWidth">
                        <span slot="suffix">周</span>
                      </el-input>
                    </el-form-item>-->
        </div>
        <h3 class="tit">实验备注</h3>
        <el-form-item label="实验备注" prop="remark">
          <el-input v-model="addExper.detail.remark" placeholder="请输入实验备注" type="textarea" rows="3"></el-input>
        </el-form-item>
        <h3 class="tit">上传附件</h3>
        <el-form-item label="上传附件" class="upload-demo">
          <el-upload
              :show-file-list="false"
              :action="upLoadUrl"
              :headers="headers"
              :data="uploadVto"
              :on-change="handleChange"
              :on-success="handleAvatarSuccess"
              :auto-upload="false"
              ref="upload"
          >
            <el-button size="small" type="primary" icon="el-icon-plus">点击上传</el-button>
          </el-upload>
          <div class="reportList flex">
            <div class="report flex" v-for="item in addExper.detail.reportList" :key="item.id">
              <span class="name">{{ item.name }}</span>
              <i class="el-icon-close" @click="deleteRecord(item.id)"></i>
            </div>
          </div>
        </el-form-item>
        <h3 class="tit">审批人员</h3>
        <el-form-item label="审批人员">
          <el-radio-group v-model="addExper.detail.apprNickname" class="radioGroup">
            <el-radio :label="item.nickname" v-for="(item,ind) in list" @change="getAppId(item.userId)"
                      :key="item.userId">
              {{ item.nickname }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="fromSave">
          <el-button type="primary" size="small" @click="addLabPlan('addExper',1)">提交</el-button>
          <el-button plain type="primary" size="small" @click="addLabPlan('addExper',0)">保存</el-button>
        </div>
      </el-form>
    </div>
    <el-dialog :show-close="false" :close-on-click-modal="false"
               class="commonDialog flex" center
               top="0"
               :visible.sync="dialogVisible"
               width="260px">
      <img src="../../../assets/img/success.png" alt="" width="52px">
      <h2>新建成功</h2>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="$router.go(-1)">返回列表页</el-button>
      </div>
    </el-dialog>
    <!--    弹框确认是否加密-->
    <el-dialog :close-on-click-modal="false"
               title="是否加密文件？"
               class="flex dialogForm "
               top="0"
               :visible.sync="dialog"
               width="450px">
      <el-form ref="formAction" size="small" :model="formTip" class="addForm" label-suffix=":"
               label-width="90px">
        <el-form-item label="加密文件">
          <el-radio-group v-model="formTip.isEncryt" class="radioGroup">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" plain type="primary" @click="dialog=false">取消</el-button>
        <el-button size="small" type="primary" @click="submitForm">提交</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "newExper",
  directives: {
    'el-select-loadmore': {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
            '.el-select-dropdown .el-select-dropdown__wrap'
        );
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition =
              this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      }
    }
  },
  data() {
    /*let checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      setTimeout(() => {
        if (value !== ''&&value !== null&&!mailReg.test(value)) {
          callback(new Error('请输入正确的邮箱格式'))
        }
      }, 100)
    };*/
    return {
      state: this.$route.query.state,
      id: this.$route.query.id,
      addExper: {
        number: "",
        name: "",
        // 方案成员
        memberList: [],
        principalName: "",
        principalPhone: "",
        principalEmail: "",
        principalId: null,
        strain: "",
        startTime: new Date(),
        period: '',//结束时间
        // period: null,
        isLabAni: 0,
        // 摘要描述 负责人（基本信息） 审批
        detail: {
          apprNickname: "",
          apprId: null,
          remark: "",
          summary: "",
          aim: "",
        },
        cageNum: null,
        femaleNum: null,
        maleNum: null,
        // 课题动物
        aniOrder: {
          grade: 0,
          // 供应商
          aniSupply: {
            prodCert: "",
            supplier: "",
          },
          applyCageNum: null,
          aniOrderDetailList: []
        },
        teamUserIds: "",
        dirNickname: "",
        // 雌性
        female: {
          count: null,
          weight: "",
          weekDays: "",
          gender: 0
        },
        // 雄性
        male: {
          count: null,
          weight: "",
          weekDays: "",
          gender: 1
        },
      },
      // 上传列表
      fileList: [],

      // 方案成员添加
      projectTeam: {
        userId: "",
        name: "",
        certNum: "",
        jobContent: "",
        phone: "",
        email: "",
        deptId: "",
        isPrincipal: 0
      },
      rules: {
        number: [
          {required: true, message: '实验编号不能为空', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '实验名称不能为空', trigger: 'blur'}
        ],
        // principalEmail: [{validator: checkEmail, trigger: 'blur'}],
        cageNum: [
          {required: true, message: '需要笼舍数量不能为空', trigger: 'blur'}
        ],
        "aniOrder.applyCageNum": [
          {required: true, message: '申请笼舍数量不能为空', trigger: 'blur'}
        ],
        femaleNum: [
          {required: true, message: '雌性入组数量不能为空', trigger: 'blur'}
        ],
        "female.count": [
          {required: true, message: '雌性实验数量不能为空', trigger: 'blur'}
        ],
        maleNum: [
          {required: true, message: '雄性入组数量不能为空', trigger: 'blur'}
        ],
        "male.count": [
          {required: true, message: '雄性实验数量不能为空', trigger: 'blur'}
        ],
      },
      dialogVisible: false,
      // 审批成员
      list: [],
      loading: false,
      userSelect: [],
      // 来源
      restaurants: [],
      formData: {   //下拉参数
        pageIndex: 1,
        pageSize: 10
      },
      // 上传附件
      upLoadUrl: this.$local.baseURL() + '/subject/report',
      uploadVto: {
        type: 0,
        isEncryt: 0
      },
      formTip: {
        isEncryt: 0
      },
      dialog: false,
      isConfirm: true,
      headers: {
        "Authentication": this.$store.state.account.token
      }
    }
  },
  created() {
    let top = {
      userId: "",
      name: "",
      certNum: "",
      jobContent: "",
      phone: "",
      email: "",
      deptId: "",
      isPrincipal: 0
    }
    this.addExper.memberList.unshift(top)
    this.addExper.memberList.map(i => {
      i.show = false
      //i.isPrincipal = 0
      return i
    })
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    }),
    pickerOptions() {
      let vm = this
      return {
        disabledDate(time) {
          return time.getTime() < new Date(vm.addExper.startTime) - 8.64e7
        },
      }
    }
  },
  mounted() {
    this.$get("/oauth/user/personal/" + this.user.companyId).then((res) => {
      if (res.status == 200) {
        this.list = res.data
      }
    })
    this.getSupply(this.formData);
    if (this.state == 1) {
      this.getEditDetail()
    }
  },
  methods: {
    cleareEndData(time) {
      let vm = this
      vm.addExper.period = ''
    },
    // 设置第一行样式
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 0) {
        return 'formAdd';
      }
      return '';
    },
    // 添加项目成员
    addTeam() {
      if ($.trim(this.projectTeam.name).length === 0) {
        this.$message.warning("成员姓名不能为空");
        return;
      }
      /*if ($.trim(this.projectTeam.certNum).length === 0) {
        this.$message.warning("专业证书编号不能为空");
        return;
      }*/
      if ($.trim(this.projectTeam.jobContent).length === 0) {
        this.$message.warning("工作内容不能为空");
        return;
      }
      if ($.trim(this.projectTeam.phone).length === 0) {
        this.$message.warning("电话号码不能为空");
        return;
      }
      /*if ($.trim(this.projectTeam.email).length === 0) {
        this.$message.warning("邮箱不能为空");
        return;
      }*/
      this.addExper.memberList.push(this.projectTeam)
      this.projectTeam = {
        userId: "",
        name: "",
        certNum: "",
        jobContent: "",
        phone: "",
        email: "",
        deptId: "",
        isPrincipal: 0,
      }
    },
    // 重置
    reset() {
      this.projectTeam = {
        userId: "",
        name: "",
        certNum: "",
        jobContent: "",
        phone: "",
        email: "",
        deptId: "",
        isPrincipal: 0
      }
    },
    // 编辑
    edit(row, index) {
      row.show = !row.show
      this.$set(this.addExper.memberList, index, row)
    },
    // 复制
    copy(val, index) {
      this.addExper.memberList.splice(index, 0, JSON.parse(JSON.stringify(val)))
    },
    // 删除
    deleteA(index) {
      this.addExper.memberList.splice(index, 1)
    },
    // 批量编辑
    editAll() {
      this.addExper.memberList.map((i, index) => {
        i.show = true
        this.$set(this.addExper.memberList, index, i)
      })
    },
    // 批量保存
    submit() {
      this.addExper.memberList.map((i, index) => {
        i.show = false
        this.$set(this.addExper.memberList, index, i)
      })
    },
    // 获取审批人id
    getAppId(id) {
      this.addExper.detail.apprId = id
    },
    // 负责人筛选
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.userSelect = this.list.filter(item => {
            return item.nickname.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.userSelect = [];
      }
    },
    handleUser(val, ind) {
      if (ind == 0) {
        this.addExper.principalId = val.userId
        this.addExper.principalPhone = val.mobile
        this.addExper.principalEmail = val.email
        this.addExper.deptId = val.deptId
      } else {
        this.projectTeam.userId = val.userId
        this.projectTeam.name = val.nickname
        this.projectTeam.phone = val.mobile
        this.projectTeam.email = val.email
        this.projectTeam.deptId = val.deptId
      }
    },
    handleUserTab(row, val) {
      row.userId = val.userId
      row.name = val.nickname
      row.phone = val.mobile
      row.email = val.email
      row.deptId = val.deptId
    },
    // 来源
    changeVal(val) {
      this.addExper.aniOrder.aniSupply.supplier = ""
      if (val == 1) {
        this.addExper.aniOrder.aniSupply.supplier = "实验室"
      }
    },
    loadAll() {
      this.formData.pageIndex++;
      this.getSupply(this.formData);
    },
    getSupply() {
      let param = {
        pageSize: this.formData.pageSize,
        pageNum: this.formData.pageIndex
      };
      this.$get("/supply/byPage", param).then(res => {
        this.restaurants = [...this.restaurants, ...res.data.records]
      }).catch(() => {
        this.$message.error('查询失败');
      })
    },
    // 上传文件
    async handleChange(files, fileList) {
      if (!this.isConfirm) {
        this.isConfirm = true
        return;
      }
      this.dialog = true
    },
    handleAvatarSuccess(response, file) {
      this.$message.success("上传成功")
      if (this.addExper.detail.reportList == null) {
        let arr = []
        arr.push(response)
        this.addExper.detail.reportList = arr
      } else {
        this.addExper.detail.reportList.push(response)
      }

    },
    submitForm() {
      this.uploadVto.isEncryt = this.formTip.isEncryt
      this.isConfirm = false;
      this.dialog = false
      this.$refs.upload.submit();
    },
    // 删除文件
    deleteRecord(id) {
      this.$confirm('是否删除该文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$put("/subject/report/" + id).then(res => {
          if (res.status == 200) {
            this.$message.success("删除成功")
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      })
    },
    // 获取编辑详情
    getEditDetail() {
      this.$get("/subject/detail/" + this.id).then((res) => {
        if (res.status == 200) {
          let newStr = {
            id: res.data.id,
            number: res.data.number,
            name: res.data.name,
            // 方案成员
            memberList: [],
            principalName: "",
            principalPhone: "",
            principalEmail: "",
            principalId: null,
            strain: res.data.strain,
            startTime: new Date(),
            period: res.data.period,
            isLabAni: res.data.isLabAni,
            // 摘要描述 负责人（基本信息） 审批
            detail: {
              subId: res.data.id,
              apprNickname: res.data.detail.apprNickname,
              apprId: res.data.detail.apprId,
              remark: res.data.detail.remark,
              summary: res.data.detail.summary,
              aim: res.data.detail.aim,
              reportList: res.data.detail.reportList
            },
            cageNum: res.data.cageNum,
            femaleNum: res.data.femaleNum,
            maleNum: res.data.maleNum,
            // 课题动物
            aniOrder: {
              grade: res.data.aniOrder.grade,
              // 供应商
              aniSupply: {
                prodCert: res.data.aniOrder.aniSupply.prodCert,
                supplier: res.data.aniOrder.aniSupply.supplier,
              },
              applyCageNum: res.data.aniOrder.applyCageNum,
              aniOrderDetailList: []
            },
            teamUserIds: res.data.teamUserIds,
            dirNickname: res.data.dirNickname,
            // 雌性
            female: {
              count: null,
              weight: "",
              weekDays: "",
              gender: 0
            },
            // 雄性
            male: {
              count: null,
              weight: "",
              weekDays: "",
              gender: 1
            },
          }
          // 成员
          res.data.memberList.forEach((item, index) => {
            console.log(item)
            if (item.isPrincipal == 1) {
              newStr.principalName = item.name
              newStr.principalPhone = item.phone
              newStr.principalEmail = item.email
              newStr.principalId = item.userId
              newStr.memberList.splice(index, 1)
            } else {
              newStr.memberList.push(item)
            }
          })
          newStr.memberList = this.addExper.memberList.concat(newStr.memberList)

          // 动物数量
          res.data.aniOrder.aniOrderDetailList.forEach(obj => {
            if (obj.gender == 0) {
              newStr.female = obj
            } else {
              newStr.male = obj
            }
          })

          this.addExper = newStr

        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 提交
    addLabPlan(approvalForm, status) {
      this.$refs[approvalForm].validate((valid) => {
        if (valid) {
          const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
          if ($.trim(this.addExper.principalEmail).length !== 0 && !mailReg.test(this.addExper.principalEmail)) {
            this.$message.warning("请填写正确的邮箱格式");
          }
          this.addExper.status = status
          this.addExper.isProd = 0
          this.addExper.memberList.splice(0, 1)
          // 品系
          this.addExper.aniOrder.strain = this.addExper.strain
          // 申请笼舍数量
          this.addExper.detail.applyCageNum = this.addExper.aniOrder.applyCageNum

          //入组和实验数量
          if ($.trim(this.addExper.femaleNum).length === 0 && $.trim(this.addExper.maleNum).length === 0) {
            this.$message.warning("请填写至少一种性别的入组数量")
            return false
          }
          if ($.trim(this.addExper.female.count).length === 0 && $.trim(this.addExper.male.count).length === 0) {
            this.$message.warning("请填写至少一种性别的实验数量")
            return false
          }
          //负责人
          let principal = {
            phone: this.addExper.principalPhone,
            email: this.addExper.principalEmail,
            userId: this.addExper.principalId,
            deptId: this.addExper.deptId,
            isPrincipal: 1
          }

          let type = typeof (this.addExper.principalName)
          if (type === 'string') {
            principal.name = this.addExper.principalName
          } else {
            principal.name = this.addExper.principalName.nickname
          }

          this.addExper.memberList.push(principal);

          if (this.addExper.memberList.length == 1) {
            this.addExper.memberList.forEach(item => {
              if (item.userId == this.user.userId) {
                this.$message.warning("请选择至少一个但不包含自己的项目成员")
              }
            })
          }
          let arr = this.addExper.memberList.map(o => o.userId)
          this.addExper.teamUserIds = arr.join(",");
          this.addExper.dirNickname = this.addExper.principalName.nickname;

          // 文件上传列表
          if (this.addExper.detail.reportList) {
            let attachIds = this.addExper.detail.reportList.map(o => o.id)
            this.addExper.detail.attachIds = attachIds.join(",");
          }
          // 雌雄性数量
          this.addExper.aniOrder.aniOrderDetailList.push(this.addExper.female)
          this.addExper.aniOrder.aniOrderDetailList.push(this.addExper.male)

          let url;
          console.log(this.addExper)

          if (this.state == 1) {
            // 编辑
            url = this.$putJson("/subject", this.addExper)
          } else {
            // 新增
            url = this.$postJson("/subject", this.addExper)
          }
          url.then((res) => {
            this.dialogVisible = true
          }).catch(() => {
            this.$message.error('提交失败')
          })

        } else {
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/exper/experiment";
</style>
